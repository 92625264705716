/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(function($){
  /* ==========================================================================
    Selectors
  ========================================================================== */
  var $window = $(window);

  /* ==========================================================================
    Breakpoints
  ========================================================================== */
  var smallestScreen = 320;
  var smallScreen = 768;
  var mediumScreen = 991;
  var largeScreen = 1440;

  function isDesktop(){
    return $window.width() > mediumScreen;
  }
  function isMobile(){
    return $window.width() <= smallScreen;
  }

  /* ==========================================================================
    Navigation
  ========================================================================== */
  var $navigationItem   = $('.nav-item');
  var $navigationDropdown = $navigationItem.find('> .dropdown');
  var $mobileToggle = $('#mobileTrigger');
  var $navMenu = $('#navMenu');

  var nav = {
    activeClass: 'open',
    navigationLink: $navigationItem,
    navigationDropdown: $navigationDropdown,
    hasDropdownClass: 'has-dropdown',
    speed: 200,
    inProgress: false,
    mobileNavOpen: false,
    reset: function(){
      $navMenu.add($navigationItem).add($navigationDropdown).removeAttr('style');
    },
    openMenuMobile: function(){
      $mobileToggle.addClass(nav.activeClass);

      $navMenu.css({display: 'flex'});
      $navMenu.animate({
        opacity: 1
      }, nav.speed, function(){
        $navigationItem.animate({
          opacity: 1,
          y: 0,         
        })
      });
    },
    closeMenuMobile: function(){
      $mobileToggle.removeClass(nav.activeClass);

      $navMenu.animate({
        opacity: 0
      }, nav.speed);
      $navigationItem.animate({
        opacity: 0,
        y: 0,
      }, nav.speed, function(){
        $navMenu.css({display: 'none'});
      });
    },
    toggleMobileMenu: function(){
      if($navMenu.is(':visible')){
        nav.closeMenuMobile();
      } else{
        nav.openMenuMobile();
      }
    },
    open: function(navItem){
      var dropdown = navItem.find('> .dropdown');
      dropdown.show();
      dropdown.animate({
        x: 0,
        opacity: 1
      }, nav.speed);
    }, 
    close: function(navItem){
      var dropdown = navItem.find('> .dropdown');
      dropdown.animate({
        x: -10,
        opacity: 0
      }, nav.speed, function(){
        dropdown.hide();
      });
    },
    openMobile: function(navItem){
      if(navItem.hasClass(this.hasDropdownClass)){
        navItem.addClass(this.activeClass);
        //Dropdown
        var dropdown = navItem.find('> .dropdown');
        var dropdownItems = dropdown.find('> li');

        //Show dropdown items & dropdown
        dropdownItems.show();
        dropdown.show();

        //Get height of dropdown items
        var dropdownItemsHeight = dropdownItems.height();
        var dropdownItemsLength = dropdownItems.length;
        var dropdownHeight = dropdownItemsHeight * (dropdownItemsLength + 1);

        //Animate
        dropdown.animate({
          height: dropdownHeight,
          opacity: 1
        }, nav.speed, function(){
          dropdownItems.animate({
            opacity: 1
          }, nav.speed)
        });
      }
    },
    closeMobile: function(navItem){
      if(navItem.hasClass(this.hasDropdownClass)){
        navItem.removeClass(this.activeClass);

        //Dropdown
        var dropdown = navItem.find('> .dropdown');
        var dropdownItems = dropdown.find('> li');

        //Animate
        dropdownItems.animate({
          opacity: 0
        }, nav.speed, function(){
          dropdown.animate({
            opacity: 0,
            height: 0
          }, nav.speed, function(){
            dropdown.add(dropdownItems).css({display: 'none'});
          });
        });
      }
    },
    toggleMobileDropdown: function(navItem){
      if(navItem.hasClass(this.activeClass)){
        nav.closeMobile(navItem);
      } else{
        nav.openMobile(navItem);
      }
    },
    init: function(){
      //desktop hovers
      $navigationItem.hover(function(){
        if(!isMobile()){
          nav.open($(this));
        }
      }, function(){
        if(!isMobile()){
          nav.close($(this));
        }
      });
      //Mobile toggle inner menu dropdown
      $navigationItem.click(function(){
        if(isMobile()){
          nav.toggleMobileDropdown($(this));
        }
      });
      //Mobile toggle menu
      $mobileToggle.on('click',function(){
        nav.toggleMobileMenu();
      });
      //Window reset
      $window.on('resize', function(){
        nav.reset();
      });
    }
  }
  nav.init();



  /* ==========================================================================
    Home Art Switcheroo
  ========================================================================== */

  var homeSwitcher = {
    activeClass: 'active',
    textClass: 'art-name',
    rotatingArtClass: 'homepage-art-box',
    rotatingArtNextClass: 'next',
    bgImageClass: 'background-art',
    foregroundImageClass: 'foreground-art',
    colorAttr: 'data-color',
    bgAttr: 'data-img',
    artIdAttr: 'data-art',
    intervalSpeed: 5000,
    intervalPaused: false,
    animSpeed: 700,
    loaderBar: $('#homeRotatorLoader'),
    textSwitch: function(selectedText){
      //Remove all active classes
      $('.'+this.textClass).removeClass(this.activeClass);
      //Add active class
      selectedText.addClass(this.activeClass);
      //Set colour
      var textColor = selectedText.attr(this.colorAttr);
      $('.'+this.textClass).css({color: textColor});
      //Change loader bar color
      this.loaderBarObj.changeColor(textColor);
    },
    imgSwitch: function(selectedText){
      var artId = selectedText.attr(this.artIdAttr);
      //Prepend to container fix
      $('.'+this.rotatingArtClass+'['+this.artIdAttr+'="'+artId+'"]').prependTo($('.art-container'));
      //Remove all items with this class, then add it to this one
      $('.'+this.rotatingArtClass).removeClass(this.activeClass);
      $('.'+this.rotatingArtClass+'['+this.artIdAttr+'="'+artId+'"]').addClass(this.activeClass);
    },
    switch: function(selectedText){
      this.textSwitch(selectedText);
      this.imgSwitch(selectedText);
    },
    interval: function(){
      var interval = setInterval(function(){
        if(homeSwitcher.intervalPaused == false){
          var activeArt = $('.'+homeSwitcher.textClass+'.'+homeSwitcher.activeClass);
          var activeNum = activeArt.index() + 1;
          var totalArtTexts = $('.'+homeSwitcher.textClass).length;
          //Switch text
          if(activeNum == totalArtTexts){
            homeSwitcher.switch($('.'+homeSwitcher.textClass+':first'));
          } else{
            homeSwitcher.switch($('.'+homeSwitcher.textClass+':eq('+(activeNum)+')'));
          }
        }
      }, homeSwitcher.intervalSpeed);
    },
    loaderBarObj: {
      init: function(){
        //Init
        //var totalTime = homeSwitcher.intervalSpeed * $('.'+homeSwitcher.textClass).length;
        //homeSwitcher.loaderBar.animate({height: '100%'}, totalTime, "linear");
      }, 
      changeColor: function(color){
        //Change Colour
        homeSwitcher.loaderBar.css({backgroundColor: color});
      }
    },
    onLoad: function(callback){
      var imgPaths = [];
      $('.'+this.textClass).each(function(){
        var img = $(this).attr(homeSwitcher.bgAttr);
        imgPaths.push(img);
      });
      var imgPathsLoaded = 0;
      for(i = 0; i < imgPaths.length; i++){
        $('<img src="'+ imgPaths[i] +'"/>').on('load', function() {
          imgPathsLoaded++;
          i++;
          if(imgPathsLoaded == imgPaths.length){
            callback();
          }
        });
      }
    },
    init: function(){ 
      this.onLoad(function(){
        homeSwitcher.switch($('.art-name[data-art="1"]'));
        homeSwitcher.interval();
        homeSwitcher.loaderBarObj.init();
        $('.home-wrapper').addClass('shown');
      });
      $('.'+this.textClass).on('click', function(){
        homeSwitcher.switch($(this));
        homeSwitcher.intervalPaused = true;
      });
    }
  }
  homeSwitcher.init();




  /* ==========================================================================
    Hover animation
  ========================================================================== */
  $('.hover-line-animation').hover(function(){
    if(!$(this).hasClass('hover') && !$(this).hasClass('unhover')){
      $(this).addClass('hover');
    } else{
      $(this).addClass('hover');
    } 
  }, function(){
    $(this).toggleClass('hover no-hover');
  });



  /* ==========================================================================
    Single page
  ========================================================================== */
  var $imgContainer = $('.portfolio-img-wrapper');

  var single = {
    wrapper: $('.single-wrapper'),
    descriptionText: $('.description-text'),
    descriptionBg: $('.description-background'),
    portraitOverlay: $('.description-master .overlay'),
    descriptionTitle: $('.description-title'),
    descriptionActiveClass: 'closed',
    speed: 400,
    positionImage: function(){
      var navList = $('.nav-list');
      var rightOffset = window.innerWidth - (navList.offset().left + navList.innerWidth());
      $imgContainer.css({right: rightOffset});
    },
    setDescHeight: function(){
      if(single.wrapper.hasClass('landscape')){
        var windowHeight = $window.height();
        var bgTopOffset = this.descriptionBg.offset().top;
        var newHeight = windowHeight - bgTopOffset;
        this.descriptionBg.css({height: newHeight});
      }
    },
    hideDescription: function(){
      single.descriptionText.animate({opacity: 0}, this.speed, function(){
        single.descriptionText.hide();
        //Landscape
        if(single.wrapper.hasClass('landscape')){
          single.descriptionBg.animate({width: 0}, single.speed, 'swing');
        //Portrait
        } else{
          single.portraitOverlay.animate({height: 0}, single.speed, 'swing');
        }
        single.descriptionTitle.addClass(single.descriptionActiveClass);
      });
    },
    showDescription: function(){
      single.descriptionText.show();
      single.descriptionTitle.removeClass(single.descriptionActiveClass);
      //Landscape
      if(single.wrapper.hasClass('landscape')){
        single.descriptionBg.animate({width: '100%'}, single.speed, 'swing', function(){
          single.descriptionText.animate({opacity: 1}, this.speed);
        });
      //Portrait
      } else{
        single.portraitOverlay.animate({height: '100%'}, single.speed, 'swing', function(){
          single.descriptionText.animate({opacity: 1}, this.speed);
        });
      }
    },
    toggleDescription: function(){
      if(this.descriptionTitle.hasClass(this.descriptionActiveClass)){
        this.showDescription();
      } else{
        this.hideDescription();
      }
    },
    init: function(){
      if($('#singleWrapper').length > 0){
        single.positionImage();
        single.setDescHeight();
        $window.on('resize', function(){
          single.positionImage();
          single.setDescHeight();
        });

        //Initiate clickz
        single.descriptionTitle.on('click', function(){
          single.toggleDescription();
        });
      }
    }
  }
  single.init();




  /* ==========================================================================
    Half-page layout
  ========================================================================== */

  var halfPage = {
    leftSide: $('.page-layout-half > .left-side'),
    setLeftPadding: function(){
      var leftPadding = $('#mainLogo').offset().left;
      this.leftSide.css({paddingLeft: leftPadding});
      this.leftSide.css({opacity: 1});
    },
    init: function(){
      if(this.leftSide.length > 0){
        $('#nav').addClass('dropshadow');
        this.setLeftPadding();
        $window.on('resize', function(){
          halfPage.setLeftPadding();
        });
      }
    }
  }
  halfPage.init();
});
